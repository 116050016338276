<template>
  <div>
    <v-row class="align-center">
      <v-col cols="12" md="1">
        <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
          <time-icon />
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <span class="label">Тип</span>
        <b-select
          :items="$directory.get('taskTypes', task.type)"
          :modelValue="task.typeId"
          :readonly="true"
          hide-details
          placeholder="Оберіть зі списку" />
      </v-col>
      <v-col cols="12" md="3">
        <span class="label">Термін виконання</span>
        <BaseDatePicker
          v-model="task.deadline"
          :error-messages="getErrorMessages(v$.deadline)"
          :loading="$loading.isLoading('updateDeadline')"
          :readonly="disabled || $loading.isLoading('updateDeadline')"
          hide-details
          time-picker
          @blur="v$.deadline.$touch()" />
      </v-col>
      <v-col
        v-if="!taskType.familiarization"
        class="d-flex align-center"
        cols="12"
        md="5">
        <v-switch
          v-model="task.isHighImportance"
          :readonly="disabled"
          class="mt-5"
          color="primary"
          hide-details
          label="Важлива задача"
          single>
        </v-switch>
      </v-col>
    </v-row>
    <v-row class="align-start">
      <v-col cols="12" md="1">
        <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
          <rules-icon />
        </v-btn>
      </v-col>
      <v-col class="d-flex flex-column gap-2" cols="12" md="11">
        <div>
          <span class="label">Тема</span>
          <b-input
            v-model="task.topic"
            :error-messages="getErrorMessages(v$.topic)"
            :readonly="task.status && task.status.id !== 1"
            hide-details
            placeholder="Вкажіть тему задачі"
            @blur="v$.topic.$touch()" />
        </div>
        <div>
          <span class="label">Опис</span>
          <BaseTextarea
            v-model="task.description"
            :error-messages="getErrorMessages(v$.description)"
            :readonly="disabled"
            hide-details
            placeholder="Додайте опис задачі"
            @blur="v$.description.$touch()" />
        </div>
      </v-col>
    </v-row>
    <v-row class="align-start">
      <v-col cols="12" md="1">
        <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
          <user-icon />
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <div class="d-flex align-center mb-1">
          <span class="label mr-2 mb-0">Виконавець</span>
          <v-tooltip
            text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
            <template #activator="{ props }">
              <v-btn density="compact" icon rounded="circle" size="x-small">
                <v-icon size="small" v-bind="props">
                  mdi-information-variant
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
        <b-server-autocomplete
          v-if="!taskType.familiarization"
          v-model="task.executorId"
          :error-messages="getErrorMessages(v$.executorId)"
          :item-title="
            u => getPersonName(u, { keyNames: { surname: 'secondName' } })
          "
          :readonly="disabled"
          :search-min-length="3"
          :url="{
            url: urlAllUsers(),
            query: { isActive: true },
          }"
          hide-details
          item-value="id"
          placeholder="Введіть мін. 3 символи"
          @blur="v$.executorId.$touch()">
        </b-server-autocomplete>
      </v-col>
      <v-col v-if="taskType.execution" cols="12" md="3">
        <div class="d-flex align-center mb-1">
          <span class="label mr-2 mb-0">Контролюючий</span>
          <v-tooltip
            text="Співробітник, який контролює завдання виконання завадання">
            <template #activator="{ props }">
              <v-btn density="compact" icon rounded="circle" size="x-small">
                <v-icon size="small" v-bind="props">
                  mdi-information-variant
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
        <b-autocomplete
          v-model="task.observerUser"
          :items="$directory.get('entityList')"
          :loading="$loading.isLoading('entityList')"
          :readonly="disabled"
          hide-details
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          return-object
          @focus="
            () => {
              if (!disabled) {
                $directory.fill({
                  name: 'entityList',
                  query: { filter: ['user', 'department'] },
                  force: true,
                })
              }
            }
          ">
        </b-autocomplete>
      </v-col>
      <v-col cols="12" md="5">
        <div class="d-flex align-center mb-1">
          <span class="label mr-2 mb-0">Наглядач</span>
          <v-tooltip
            text="Співробітник, який стежить за перебігом завдання та може залишати коментарі">
            <template #activator="{ props }">
              <v-btn density="compact" icon rounded="circle" size="x-small">
                <v-icon size="small" v-bind="props">
                  mdi-information-variant
                </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
        <b-autocomplete
          v-model="task.controlUser"
          :items="$directory.get('entityList')"
          :loading="$loading.isLoading('entityList')"
          :readonly="disabled"
          hide-details
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          return-object
          @focus="
            () => {
              if (!disabled) {
                $directory.fill({
                  name: 'entityList',
                  query: { filter: ['user', 'department'] },
                  force: true,
                })
              }
            }
          ">
        </b-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="11">
        <v-btn-group
          v-if="!showContractor"
          density="compact"
          rounded="lg"
          variant="outlined">
          <v-btn
            color="primary"
            density="comfortable"
            rounded="lg"
            size="x-small"
            @click="showContractor = true">
            <v-icon class="mr-1" color="primary">mdi-plus</v-icon>
            Контрагент
          </v-btn>
        </v-btn-group>
        <div v-if="showContractor">
          <span class="label">Контрагент</span>
          <b-autocomplete
            v-model="task.contractorId"
            :items="$directory.get('contractorList', task.contractor)"
            :loading="$loading.isLoading('contractorList')"
            :readonly="disabled"
            hide-details
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            showClear
            @focus="
              () => {
                if (!disabled) {
                  $directory.fill('contractorList')
                }
              }
            ">
            <template #append>
              <v-btn
                icon="mdi-close"
                variant="text"
                @click="
                  () => {
                    showContractor = false
                    task.contractorId = null
                  }
                "></v-btn>
            </template>
          </b-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row class="align-start">
      <v-col cols="12" md="1">
        <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
          <attachment-icon />
        </v-btn>
      </v-col>
      <v-col class="pt-5" cols="12" md="11">
        <FilesUploader
          v-model="task.attachment"
          :prefix-folder="void [0]"
          :readonly="disabled"
          :v$="v$.attachment"
          file-list
          label="Файли"
          main-folder="Документы"
          name-folder="'template'"
          noCard>
        </FilesUploader>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import RulesIcon from '@/assets/images/icons/rules-icon.vue'
import { useCreateTask } from '@/pages/tasks/helpers/createTask'
import { getErrorMessages } from 'best-modules/utils'
import TimeIcon from '@/assets/images/icons/time-icon.vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { getPersonName } from 'best-modules/utils/helpers'
import { axios } from '@/plugins'
import { urlAllUsers, urlTaskUpdateExecutor } from '@/utils/urls'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Task } from '@/utils/types/task'
import UserIcon from '@/assets/images/icons/user-icon.vue'
import { ref } from 'vue'
import AttachmentIcon from '@/assets/images/icons/attachment-icon.vue'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import { omit } from 'lodash'
import { BServerAutocomplete } from 'best-modules/components/index'

export default {
  name: 'CreateTask',
  components: {
    FilesUploader,
    AttachmentIcon,
    UserIcon,
    BaseDatePicker,
    TimeIcon,
    RulesIcon,
    BServerAutocomplete,
  },
  props: {
    dialog: {
      type: Object,
      default: null,
    },
  },
  methods: { urlAllUsers, getPersonName, getErrorMessages },
  setup(props) {
    const useTask = useCreateTask(props.dialog.dialogData.taskData)
    const showContractor = ref(false)

    const { task } = useTask as {
      task: CachedObjectProxy<Task>
    }

    Object.assign(task, props.dialog.dialogData.taskData || {})

    const updateExecutor = () => {
      return axios.post(urlTaskUpdateExecutor(task.id), {
        executorId: task.executorId,
      })
    }

    return {
      showContractor,
      updateExecutor,
      task,
      ...omit(useTask, 'task'),
    }
  },
}
</script>

<style lang="scss" scoped></style>
