<template>
  <div>
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2" style="height: 45px">
        <h4>Юридична інформація</h4>
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-2">
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Тип</span>
          <div class="w-55">
            <b-select
              v-model="contractorData.typeId"
              :error-messages="getErrorMessages(v$.typeId)"
              :items="$directory.get('contractorTypes', contractorData.type)"
              :loading="$loading.isLoading('contractorTypes')"
              hide-details
              placeholder="Оберіть тип"
              @blur="v$.typeId.$touch()"
              @focus="$directory.fill('contractorTypes')"></b-select>
          </div>
        </div>
        <div
          v-show="contractorData.typeId === 2"
          class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Номер реєстрації</span>
          <div class="w-55">
            <b-input
              v-model="contractorData.stateRegistrationNumber"
              :mask="'#'.repeat(20)"
              hide-details
              placeholder="Введіть номер"></b-input>
          </div>
        </div>
        <div
          v-show="contractorData.typeId === 2"
          class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Дата реєстрації</span>
          <div class="w-55">
            <BaseDatePicker
              v-model="contractorData.stateRegistrationDate"
              hide-details></BaseDatePicker>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Директор</span>
          <div class="w-55">
            <b-select
              v-model="contractorData.directorId"
              :items="
                $directory
                  .get('contractorList', contractorData.director)
                  .filter(c => c.typeId === 1)
              "
              :loading="$loading.isLoading('contractorList')"
              hide-details
              placeholder="Оберіть зі списку"
              @focus="$directory.fill('contractorList')"></b-select>
          </div>
        </div>
        <slot v-if="$slots['action-buttons']" name="action-buttons"></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { getErrorMessages } from 'best-modules/utils'
import { computed } from 'vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'

export default {
  name: 'ContractorSidebar',
  components: { BaseDatePicker },
  emits: ['update:contractor'],
  props: {
    contractor: { type: Object, required: true },
    v$: { type: Object },
  },
  methods: { getErrorMessages },
  setup(props, { emit }) {
    const contractorData = computed({
      get() {
        return props.contractor
      },
      set(v) {
        emit('update:contractor', v)
      },
    })

    return { contractorData }
  },
}
</script>

<style lang="scss" scoped></style>
