<template>
  <v-row class="align-center mb-4" style="justify-content: space-between">
    <v-col cols="12" md="12" sm="12">
      <b-server-autocomplete
        v-model="selectRelatedId"
        :menu-props="{ width: '500px' }"
        :search-min-length="3"
        :url="urlTasks()"
        hide-details
        item-title="topic"
        item-value="id"
        label="Задача"
        placeholder="Введіть мін. 3 символи">
        <template #append>
          <v-btn
            color="primary"
            density="comfortable"
            icon
            rounded="lg"
            size="small"
            @click="createRelatedTask">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </template>
      </b-server-autocomplete>
    </v-col>
    <v-col v-if="relatedTasks.length" cols="12" md="12" sm="12">
      <CustomItem
        v-for="task in relatedTasks"
        :key="task.id"
        :item="task"
        hidePost>
        <template #actions>
          <v-btn
            color="primary"
            density="comfortable"
            icon
            rounded="lg"
            size="small"
            @click="unrelatedTask(task.id)">
            <v-icon color="white">mdi-delete-forever</v-icon>
          </v-btn>
        </template>
      </CustomItem>
    </v-col>
  </v-row>
</template>

<script>
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import { urlCreateRelatedTask, urlTasks, urlUnrelatedTask } from '@/utils/urls'
import { ref } from 'vue'
import { useRoute } from '@/plugins'
import { axios } from '@/plugins/index'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  name: 'RelatedTask',
  components: { CustomItem, BServerAutocomplete },
  props: {
    dialog: { type: Object },
  },
  methods: { urlTasks },
  setup(props) {
    const route = useRoute()
    const relatedTasks = ref(props.dialog.dialogData.task?.related_tasks || [])
    const selectRelatedId = ref(null)

    const unrelatedTask = id => {
      return axios
        .post(urlUnrelatedTask(+route.params.id), {
          relatedId: id,
        })
        .then(res => {
          if (res.data.related_tasks) {
            relatedTasks.value = res.data?.related_tasks
          } else {
            relatedTasks.value = []
          }
          return res
        })
    }
    const createRelatedTask = () => {
      return axios
        .post(urlCreateRelatedTask(+route.params.id), {
          relatedId: selectRelatedId.value,
        })
        .then(res => {
          selectRelatedId.value = null
          relatedTasks.value = []
          relatedTasks.value = res.data?.related_tasks
          return res
        })
    }

    return {
      selectRelatedId,
      unrelatedTask,
      createRelatedTask,
      relatedTasks,
    }
  },
}
</script>

<style lang="scss" scoped></style>
