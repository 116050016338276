import { axios, useStore } from '@/plugins'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import {
  urlTaskComplete,
  urlTaskDelete,
  urlTaskReject,
  urlTaskTakeToWork,
} from '@/utils/urls.js'
import { computed } from 'vue'
import { confirm } from '@/plugins/confirm'
import { openDialog } from '@/dialog'
import { useRoute } from 'vue-router'

export function useTaskActions() {
  const store = useStore()
  const route = useRoute()
  const userId = computed(() => store.state.user.userData?.id)
  const absenceId = route?.params?.absenceId
  const disabled = task => {
    const isExecutor = computed(() =>
      [task.executorId, task.executor?.id].includes(userId.value)
    )
    const isAuthor = computed(() => {
      return [task.authorId, task.author?.id].includes(userId.value)
    })

    const takeToWork = computed(() => !isExecutor.value || task.statusId !== 1)
    const complete = computed(() => !isExecutor.value || task.statusId !== 2)
    const copy = computed(() => !isAuthor.value)
    const delegate = computed(() => {
      return (
        !!isAuthor.value || task.isDelegated || [3, 4].includes(task.statusId)
      )
    })
    const reject = computed(() => {
      return isAuthor.value || [3, 4].includes(task.statusId)
    })
    const del = computed(() => !isAuthor.value)

    return {
      takeToWork,
      complete,
      copy,
      delegate,
      reject,
      del,
    }
  }
  const taskTakeToWork = (task, loaderName = 'taskTakeToWork') => {
    return handleAsync(loaderName, () => {
      return axios.get(urlTaskTakeToWork(task.id, absenceId)).then(res => {
        task.statusId = 2
        task.status = res.data.status
        return res.data
      })
    })
  }
  const taskComplete = (
    task,
    loaderName = 'taskComplete',
    onClose = () => false
  ) => {
    openDialog({
      name: 'Textarea',
      params: {
        cb(completionReport) {
          return handleAsync(loaderName, () => {
            return axios
              .post(urlTaskComplete(task.id, absenceId), { completionReport })
              .then(res => {
                task.statusId = 3
                task.status = res.data.status
                task.completedDate = res.data?.completedDate
                task.completionReport = res.data?.completionReport
                setSnackbar({ text: 'Завдання завершено' })
                onClose(res.data)
              })
          })
        },
      },
      dialogData: {
        placeholder: 'Звіт по виконанню задачі',
        dialogTitle: 'Завершення задачі',
      },
    })
  }

  const taskReject = (
    task,
    loaderName = 'taskReject',
    onClose = () => false
  ) => {
    openDialog({
      name: 'Textarea',
      params: {
        cb(rejectComment) {
          const req = { rejectComment }
          handleAsync(loaderName, () => {
            return axios
              .post(urlTaskReject(task.id, absenceId), req)
              .then(res => {
                task.statusId = 4
                task.status = res.data.status
                setSnackbar({ text: 'Завдання завершено' })
                onClose(res.data)
              })
          })
        },
      },
      dialogData: {
        dialogTitle: 'Відхилення задачі',
        placeholder: 'Коментар до відхилення',
      },
    })
  }
  const taskDelete = (task, loaderName = 'taskDelete', onClose) => {
    confirm({
      text: 'Ви впевненні що хочете видалити завдання назавжди?',
    }).then(() => {
      return handleAsync(loaderName, () => {
        return axios.delete(urlTaskDelete(task.id)).then(() => {
          setSnackbar({ text: 'Завдання видалено' })
          onClose(task.id)
        })
      })
    })
  }

  const taskActions = (task, taskType) => {
    if (!taskType.familiarization) {
      return [
        {
          label: 'Взяти в роботу',
          disabled: disabled(task).takeToWork.value,
          action: () => taskTakeToWork(task),
          icon: 'mdi-timer-play-outline',
        },
        {
          label: 'Завершити задачу',
          disabled: disabled(task).complete.value,
          action: () => taskComplete(task),
          icon: 'mdi-table-check',
        },
        {
          label: 'Відхилити задачу',
          disabled: disabled(task).reject.value,
          action: () => taskReject(task),
          icon: 'mdi-close-box',
        },
        {
          label: 'Видалити задачу',
          disabled: disabled(task).del.value,
          action: () => taskDelete(task),
          icon: 'mdi-trash-can-outline',
        },
      ]
    } else
      return [
        {
          label: 'Взяти в роботу',
          disabled: disabled(task).takeToWork.value,
          action: () => taskTakeToWork(task),
          icon: 'mdi-play',
        },
        {
          label: 'Завершити задачу',
          disabled: disabled(task).complete.value,
          action: () => taskComplete(task),
          icon: 'mdi-check-circle',
        },
      ]
  }
  return {
    disabled,
    taskTakeToWork,
    taskComplete,
    taskReject,
    taskDelete,
    taskActions,
  }
}
