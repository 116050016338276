<template>
  <div>
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-text
        v-for="(status, index) in visibleStatusCards"
        :key="index"
        :class="status.class"
        class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-clock-fast</v-icon>
          <strong>{{ status.title }}</strong>
        </div>
        <v-tooltip v-if="task.completionReport" :text="task.completionReport">
          <template #activator="{ props }">
            <v-btn
              class="ml-2"
              color="white"
              density="compact"
              icon
              rounded="circle"
              size="x-small">
              <v-icon color="black" size="x-small" v-bind="props"
                >mdi-information-variant
              </v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-card-text>
      <v-card-text v-if="task.isOverdue" style="color: red">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-timer-cancel-outline</v-icon>
          <strong>Завдання протерміноване!</strong>
        </div>
      </v-card-text>
      <v-card-text class="d-flex flex-column gap-2">
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Дата створення</span>
          <span class="label mb-0" style="font-size: 14px">
            {{ new CustomDate(task.created_at).toString({ time: true }) }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Видав</span>
          <span class="label mb-0" style="font-size: 14px">
            {{
              getPersonName(task.author, {
                keyNames: { surname: 'secondName' },
                initials: true,
              })
            }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Тип задачі</span>
          <span class="label mb-0" style="font-size: 14px">
            {{ task?.type?.name }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Термін виконання</span>
          <div class="w-75">
            <BaseDatePicker
              v-model="taskData.deadline"
              :error-messages="getErrorMessages(v$.deadline)"
              :loading="$loading.isLoading('updateDeadline')"
              :readonly="
                disabled ||
                $loading.isLoading('updateDeadline') ||
                taskData?.statusId !== 1
              "
              hide-details
              time-picker
              @blur="v$.deadline.$touch()"
              @update:model-value="updateDeadline" />
          </div>
        </div>
        <div
          v-if="taskData.typeId !== 2"
          class="d-flex align-center justify-space-between w-100">
          <div class="d-flex align-center mb-1 w-45">
            <span class="label d-block mr-2 mb-0">Виконавець</span>
            <v-tooltip
              text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
              <template #activator="{ props }">
                <v-btn density="compact" icon rounded="circle" size="x-small">
                  <v-icon size="small" v-bind="props">
                    mdi-information-variant
                  </v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
          <div class="w-75">
            <b-server-autocomplete
              v-model="taskData.executorId"
              :default-item="task.executor"
              :error-messages="getErrorMessages(v$.executorId)"
              :item-title="
                u =>
                  u.secondName
                    ? getPersonName(u, {
                        keyNames: { surname: 'secondName' },
                        initials: true,
                      })
                    : u.name
              "
              :loading="$loading.isLoading(['userList, updateExecutor'])"
              :readonly="disabled || taskData.statusId !== 1"
              :search-min-length="3"
              :url="{
                url: urlUserList(),
                query: { isActive: true },
              }"
              hide-details
              item-value="id"
              placeholder="Введіть мін. 3 символи"
              @blur="v$.executorId.$touch()"
              @focus="
                !disabled &&
                  taskData.statusId === 1 &&
                  $directory.fill('userList')
              "
              @update:model-value="updateExecutor">
            </b-server-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <div class="d-flex align-center mb-1 w-45">
            <span class="label d-block mb-0 mr-2">Контролюючий</span>
            <v-tooltip
              text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
              <template #activator="{ props }">
                <v-btn density="compact" icon rounded="circle" size="x-small">
                  <v-icon size="small" v-bind="props">
                    mdi-information-variant
                  </v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
          <div class="w-75">
            <b-autocomplete
              v-model="taskData.control_user"
              :item-title="
                u =>
                  u?.user
                    ? getPersonName(u?.user, {
                        keyNames: { surname: 'secondName' },
                        initials: true,
                      })
                    : u.name
              "
              :items="$directory.get('userList', task.control_user)"
              :loading="$loading.isLoading('userList')"
              :readonly="
                disabled || taskData.control_user || taskData.statusId !== 1
              "
              hide-details
              item-value="id"
              placeholder="Оберіть зі списку"
              return-object
              @focus="
                !disabled &&
                  taskData.statusId === 1 &&
                  $directory.fill('userList')
              "
              @update:model-value="createControlUser">
              <template v-if="taskData.control_user" #append>
                <v-btn
                  :disabled="disabled"
                  color="primary"
                  density="comfortable"
                  icon
                  rounded="lg"
                  size="small"
                  @click="deleteControlUser">
                  <v-icon color="white">mdi-delete-forever</v-icon>
                </v-btn>
              </template>
            </b-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <div class="d-flex align-center mb-1 w-45">
            <span class="label d-block mr-2 mb-0">Наглядач</span>
            <v-tooltip
              text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
              <template #activator="{ props }">
                <v-btn density="compact" icon rounded="circle" size="x-small">
                  <v-icon size="small" v-bind="props">
                    mdi-information-variant
                  </v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
          <div class="w-75">
            <b-autocomplete
              v-model="taskData.observer"
              :item-title="
                u =>
                  u?.user
                    ? getPersonName(u?.user, {
                        keyNames: { surname: 'secondName' },
                        initials: true,
                      })
                    : u.name
              "
              :items="$directory.get('entityList', task.observer)"
              :loading="$loading.isLoading('entityList')"
              :readonly="
                disabled || taskData.observer || taskData.statusId !== 1
              "
              hide-details
              item-value="id"
              placeholder="Оберіть зі списку"
              return-object
              @focus="
                () => {
                  if (!disabled && taskData.statusId === 1) {
                    $directory.fill({
                      name: 'entityList',
                      query: { filter: ['user', 'department'] },
                      force: true,
                    })
                  }
                }
              "
              @update:model-value="createObserverUser">
              <template v-if="taskData.observer" #append>
                <v-btn
                  :disabled="disabled || taskData.statusId !== 1"
                  color="primary"
                  density="comfortable"
                  icon
                  rounded="lg"
                  size="small"
                  @click="deleteObserverUser">
                  <v-icon color="white">mdi-delete-forever</v-icon>
                </v-btn>
              </template>
            </b-autocomplete>
          </div>
        </div>

        <div
          v-if="taskData.statusId === 5 && user?.id === taskData?.authorId"
          class="d-flex flex-column gap-1 mt-2">
          <v-btn
            :loading="$loading.isLoading('finalCompleteHandler')"
            color="green"
            rounded="lg"
            @click="finalComplete">
            Прийняти роботу
          </v-btn>
          <v-btn
            :loading="$loading.isLoading('revisionTaskHandler')"
            color="red"
            rounded="lg"
            @click="revisionTask">
            Відправити на доопрацювання
          </v-btn>
        </div>
        <slot v-if="$slots['action-buttons']" name="action-buttons"></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { CustomDate, getErrorMessages } from 'best-modules/utils'
import { getPersonName } from 'best-modules/utils/helpers'
import { computed, ref } from 'vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { axios } from '@/plugins'
import {
  urlTaskCreateControlUser,
  urlTaskCreateObserverUser,
  urlTaskDeleteControlUser,
  urlTaskDeleteObserverUser,
  urlTaskFinalComplete,
  urlTaskRevision,
  urlTaskUpdateDeadline,
  urlTaskUpdateExecutor,
  urlUserList,
} from '@/utils/urls'
import { useRoute } from 'vue-router'
import { BServerAutocomplete } from 'best-modules/components'
import { useStore } from 'vuex'

export default {
  name: 'Sidebar',
  emits: ['update:task'],
  components: {
    BServerAutocomplete,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      default: () => ({ $validate: () => false }),
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  methods: {
    getErrorMessages,
    getPersonName,
    CustomDate,
  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const previousDeadline = ref(null)
    const absenceId = route?.params?.absenceId
    const user = computed(() => store.state.user.userData)

    const taskData = computed({
      get() {
        return props.task
      },
      set(val) {
        emit('update:task', val)
      },
    })

    const updateDeadline = async (): Promise<void> => {
      if (taskData.value.deadline === previousDeadline.value) {
        return
      }

      previousDeadline.value = taskData.value.deadline

      return handleAsync('updateDeadline', async () => {
        if (taskData.value.deadline && taskData.value.deadline.length === 17) {
          await axios.post(urlTaskUpdateDeadline(props.task.id, absenceId), {
            deadline: taskData.value.deadline,
          })
        }
      })
    }

    const finalComplete = async (): Promise<void> => {
      return handleAsync('finalCompleteHandler', async () => {
        await axios
          .get(urlTaskFinalComplete(props.task.id, absenceId))
          .then(res => {
            Object.assign(taskData.value, res.data)
            setSnackbar({ text: 'Завдання перевірено і завершено' })
          })
      })
    }

    const revisionTask = async (): Promise<void> => {
      return handleAsync('revisionTaskHandler', async () => {
        await axios.get(urlTaskRevision(props.task.id, absenceId)).then(res => {
          Object.assign(taskData.value, res.data)
          setSnackbar({
            text: 'Завдання відправлено на доопрацювання виконавцю',
          })
        })
      })
    }

    const getStatusCard = computed(() => [
      {
        showIf: props.task.status?.id === 1,
        title: `${props.task.status?.name} з ${props.task.created_at}`,
        class: 'bg-yellow-lighten-1',
      },
      {
        showIf: props.task.status?.id === 2,
        title: `Завдання ${props.task.status?.name.toLowerCase()}`,
        class: 'bg-blue',
      },
      {
        showIf: props.task.status?.id === 3,
        title: `Завдання ${props.task.status?.name.toLowerCase()} ${
          props.task.completedDate
        }`,
        class: 'bg-green',
      },
      {
        showIf: props.task.status?.id === 5,
        title: props.task.status?.name,
        class: 'bg-yellow-lighten-1',
      },
    ])

    const visibleStatusCards = computed(() =>
      getStatusCard.value.filter(status => status.showIf)
    )

    const createControlUser = obj => {
      const req = {
        taskId: props.task.id,
        userId: obj?.id,
      }
      return axios.post(urlTaskCreateControlUser(), req).then(() => {
        setSnackbar({ text: 'Дані оновлено' })
      })
    }

    const updateExecutor = executorId => {
      if (!executorId) return
      return handleAsync('updateExecutor', () => {
        return axios
          .post(urlTaskUpdateExecutor(props.task.id), {
            executorId: executorId,
          })
          .then(() => {
            setSnackbar({ text: 'Виконавця оновлено' })
          })
      })
    }

    const deleteControlUser = () => {
      return axios
        .delete(
          urlTaskDeleteControlUser(
            props.task.control_user?.id || props.task.control_user?.userId,
            absenceId
          )
        )
        .then(() => {
          taskData.value.control_user = null
          setSnackbar({ text: 'Дані оновлено' })
        })
    }

    const createObserverUser = obj => {
      const req = {
        entityId: obj.entityId,
        entityTypeId: obj.entityTypeId,
        taskId: props.task.id,
      }
      return axios.post(urlTaskCreateObserverUser(), req).then(() => {
        setSnackbar({ text: 'Дані оновлено' })
      })
    }

    const deleteObserverUser = () => {
      return axios
        .delete(
          urlTaskDeleteObserverUser(
            props.task.observer?.entityId || props.task.observer?.userId,
            absenceId
          )
        )
        .then(() => {
          taskData.value.observer = null
          setSnackbar({ text: 'Дані оновлено' })
        })
    }

    return {
      user,
      urlUserList,
      updateExecutor,
      taskData,
      finalComplete,
      revisionTask,
      updateDeadline,
      getStatusCard,
      visibleStatusCards,
      createControlUser,
      deleteControlUser,
      createObserverUser,
      deleteObserverUser,
    }
  },
}
</script>

<style lang="scss" scoped></style>
