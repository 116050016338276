<template>
  <svg
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M14 5.5V14.5H5V5.5H14ZM14 4.5H5C4.73478 4.5 4.48043 4.60536 4.29289 4.79289C4.10536 4.98043 4 5.23478 4 5.5V14.5C4 14.7652 4.10536 15.0196 4.29289 15.2071C4.48043 15.3946 4.73478 15.5 5 15.5H14C14.2652 15.5 14.5196 15.3946 14.7071 15.2071C14.8946 15.0196 15 14.7652 15 14.5V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5Z" />
    <path
      :fill="color"
      d="M2 9.5H1V2.5C1 2.23478 1.10536 1.98043 1.29289 1.79289C1.48043 1.60536 1.73478 1.5 2 1.5H9V2.5H2V9.5Z" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'CopyIcon',
  props: {
    color: { type: String, default: '#8E92BC' },
  },
}
</script>

<style lang="scss" scoped></style>
