<template>
  <div>
    <b-section-loader :loading="initLoading">
      <div class="cursor-default">
        <v-row>
          <v-col
            :class="!$vuetify.display.smAndUp ? null : 'pr-0'"
            cols="12"
            md="8"
            sm="12">
            <v-card class="shadow-1" rounded="lg">
              <v-card-title class="mt-1 mb-2">
                <div
                  :class="
                    $vuetify.display.smAndUp
                      ? 'align-center'
                      : 'flex-column align-start'
                  "
                  class="d-flex justify-space-between">
                  <h4>Завдання</h4>
                  <div class="d-flex align-center gap-2">
                    <v-checkbox
                      v-model="task.isCEO"
                      :readonly="disabled"
                      hide-details>
                      <template #label>
                        <span class="label mb-0">Задача від ГД</span>
                      </template>
                    </v-checkbox>
                    <v-switch
                      v-if="!taskType.familiarization"
                      v-model="task.isHighImportance"
                      :readonly="disabled"
                      color="primary"
                      hide-details
                      single>
                      <template #label>
                        <span class="label mb-0">Важлива задача</span>
                      </template>
                    </v-switch>
                    <v-btn-group
                      color="surface"
                      density="compact"
                      divided
                      rounded="xs"
                      variant="flat">
                      <v-tooltip text="Копіювати задачу">
                        <template #activator="{ props }">
                          <v-btn
                            :readonly="!isAuthor"
                            size="x-small"
                            v-bind="props"
                            @click="copyTaskHandler">
                            <copy-icon color="black" />
                          </v-btn>
                        </template>
                      </v-tooltip>
                      <v-tooltip text="Зв'язки">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            v-bind="props"
                            @click="connectionHandler">
                            <connect-icon color="black" />
                          </v-btn>
                        </template>
                      </v-tooltip>
                      <v-tooltip text="Історія">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            v-bind="props"
                            @click="
                              setSnackbar({
                                text: 'В розробці',
                                color: 'warning',
                              })
                            ">
                            <v-icon>mdi-clipboard-text-clock-outline</v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                      <TaskActions
                        :actions="actions.taskActions(task.$object, taskType)">
                      </TaskActions>
                    </v-btn-group>
                  </div>
                </div>
              </v-card-title>
              <v-card-text class="gap-2 d-flex flex-column">
                <div>
                  <span class="label">Тема</span>
                  <b-input
                    v-model="task.topic"
                    :error-messages="getErrorMessages(v$.topic)"
                    :readonly="disabled"
                    class="w-100"
                    hide-details
                    placeholder="Введіть текст"
                    @blur="v$.topic.$touch()" />
                </div>
                <div v-if="!taskType.errand">
                  <span class="label">Опис</span>
                  <BaseTextarea
                    v-model="task.description"
                    :error-messages="getErrorMessages(v$.description)"
                    :readonly="disabled"
                    class="w-100"
                    hide-details
                    placeholder="Введіть текст"
                    @blur="v$.description.$touch()" />
                </div>
                <div>
                  <div
                    v-if="!task.contractorId && !addContractor"
                    class="d-flex justify-end w-100">
                    <v-hover v-slot="{ isHovering, props }">
                      <span
                        class="link"
                        v-bind="props"
                        @click="addContractor = true">
                        <v-icon
                          :style="{
                            transform: isHovering
                              ? 'rotate(45deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out',
                          }">
                          mdi-plus
                        </v-icon>
                        <span>Зв'язок</span>
                      </span>
                    </v-hover>
                  </div>
                  <div v-else>
                    <span class="label">Контрагент</span>
                    <b-server-autocomplete
                      v-model="task.contractorId"
                      :default-item="task.contractor"
                      :menu-props="{ width: '400px' }"
                      :readonly="disabled"
                      :search-min-length="3"
                      :url="urlContractorList()"
                      class="w-100"
                      hide-details
                      item-title="name"
                      item-value="id"
                      placeholder="Введіть мін. 3 символи"
                      showClear>
                      <template v-if="task.contractorId" #append>
                        <v-btn
                          :disabled="disabled"
                          color="primary"
                          density="comfortable"
                          icon
                          rounded="lg"
                          size="small"
                          @click="
                            () => {
                              task.contractorId = null
                              task.contractor = null
                            }
                          ">
                          <v-icon color="white">mdi-delete-forever</v-icon>
                        </v-btn>
                      </template>
                    </b-server-autocomplete>
                  </div>
                </div>
                <FilesUploader
                  v-model="task.attachment"
                  :disabled="disabled"
                  :name-folder="task.created_at.substring(6, 10)"
                  :prefix-folder="task.created_at.substring(3, 5)"
                  :v$="v$.attachment"
                  file-list
                  label="Файли"
                  main-folder="Документы"
                  noCard>
                </FilesUploader>
              </v-card-text>
            </v-card>
            <Comments
              :comments="task.comments"
              :disabled="task.statusId === 3"
              :entity-id="task.id"
              :entity-type-id="1"
              :hideDelete="false" />
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <Sidebar
              v-model:task="task"
              :disabled="disabled"
              :readonly="disabled"
              :v$="v$">
              <template #action-buttons>
                <SubmitButtons
                  v-if="!disabled && task.$hasChanges"
                  :disabled="!task.$hasChanges"
                  :submit="submit"
                  class="mt-10 d-flex justify-end"
                  compact
                  submitLabel="Оновити"
                  @cancel="cancel" />
              </template>
            </Sidebar>
          </v-col>
        </v-row>
      </div>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import SubmitButtons from '@/components/SubmitButtons.vue'
import { ref } from 'vue'
import { useEditTask } from '@/pages/tasks/helpers/editTask'
import { useTaskActions } from '@/pages/tasks/helpers/taskActions.js'
import { getErrorMessages, getUserName, navigateToItem } from '@/utils/helpers'
import { fillDirectory } from '@/directory'
import { socket, taskNotification } from '@/socket'
import { Task } from '@/utils/types/task'
import { omit } from 'lodash'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import {
  BSectionLoader,
  BServerAutocomplete,
} from 'best-modules/components/index'
import Sidebar from '@/pages/tasks/components/Sidebar.vue'
import CopyIcon from '@/assets/images/icons/copy-icon.vue'
import ConnectIcon from '@/assets/images/icons/connect-icon.vue'
import TaskActions from '@/pages/tasks/components/TaskActions.vue'
import Comments from '@/components/comments/Comments.vue'
import { urlContractorList } from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins'

export default {
  components: {
    Comments,
    TaskActions,
    ConnectIcon,
    CopyIcon,
    Sidebar,
    SubmitButtons,
    BSectionLoader,
    BServerAutocomplete,
  },
  methods: {
    setSnackbar,
    fillDirectory,
    navigateToItem,
    getUserName,
    getErrorMessages,
  },
  setup() {
    const initLoading = ref(true)
    const addContractor = ref(false)
    const actions = useTaskActions()
    const useTask = useEditTask()
    const { task } = useTask as { task: CachedObjectProxy<Task> }

    Promise.all([
      'getTask' in useTask ? useTask.getTask() : Promise.resolve(),
    ]).then(() => {
      socket.on(taskNotification, res => {
        if (res.id === task.id) {
          task.$set(res)
        }
      })
      initLoading.value = false
    })

    const getTooltipText = entityTypeId => {
      switch (entityTypeId) {
        case 3:
          return 'Перейти в картку наказу'
        case 4:
          return 'Перейти в картку кореспонденції'
        case 9:
          return 'Перейти в картку аудиту'
        default:
          return 'Перейти в картку'
      }
    }

    const getNavigationPath = entityTypeId => {
      switch (entityTypeId) {
        case 3:
          return 'single-order'
        case 4:
          return 'correspondence-single'
        case 9:
          return 'audit-single'
        default:
          return 'unknown-path'
      }
    }

    const copyTaskHandler = () => {
      return useTask.copyTask(task)
    }

    const connectionHandler = () => {
      return useTask.openTaskRelatedDialog(task)
    }

    return {
      copyTaskHandler,
      initLoading,
      actions,
      task,
      getTooltipText,
      getNavigationPath,
      connectionHandler,
      urlContractorList,
      addContractor,
      ...omit(useTask, 'task'),
    }
  },
}
</script>

<style lang="scss" scoped>
.buttons > * {
  display: block;
  margin-bottom: 1rem;
}
</style>
